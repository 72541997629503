export const WorkData = [



  {
    company: "Edutech System Ltd",
    designation: "Junior Software Developer (R&D Intern)",
    dateJoining: "Sept.2020",
    dateEnd: "Feb.2021",
    companyLogo:
      "",
    work: `I had been working alone on an initial prototype of a centralized primary
    smart education system where a classroom’s whiteboard will be replaced
    with a single board computer’s display that is known here as Node. The node
    would be connected with the central server application and it would be
    continuously sending and receiving all real-time data to Server Dashboard.
    Central Dashboard had the ability to control and monitor the activity of node
    devices. The full system cycle would be automated and node application
    would be voice-controlled.`,
  },
  {
    company: " Metamorphosis Ltd",
    designation: "Junior Python Developer (Intern) ",
    dateJoining: "Feb.2020",
    dateEnd: "Feb.2020 - Sept.2021",
    companyLogo:
      "",
    work: `● Got the concept of Odoo ERP and worked on custom module development.
    ● Pre-built custom modules have been upgraded from Python 2 to Python 3.`,
  },
];
