export const SocialData = [
  {
    platform: "Instagram",
    icon: require("../assets/icons/instagram.png").default,
    link: "www.instagram.com/icerahi",
  },
  {
    platform: "Github",
    icon: require("../assets/icons/github.png").default,
    link: "www.github.com/icerahi",
  },
  {
    platform: "Linkedin",
    icon: require("../assets/icons/linkedin.png").default,
    link: "www.linkedin.com/in/icerahi",
  },
  {
    platform: "Facebook",
    icon: require("../assets/icons/facebook.png").default,
    link: "www.facebook.com/icerahi",
  },
 
];
