export const SkillsData = [
  {
    type: "Language",
    list: [
      {
        name: "Javascript",
        icon: <i class="devicon-javascript-plain colored"></i>
        ,
      },
      {
        name: "Python",
        icon:<i class="devicon-python-plain-wordmark colored"></i>
        ,
      },
    ],
  },
  {
    type: "Frontend",
    list: [
      {
        name: "ReactJS",
        icon:<i class="devicon-react-original-wordmark colored"></i>

        ,
      },
       
     
      {
        name: "HTML",
        icon: <i class="devicon-html5-plain-wordmark colored"></i>
        ,
      },
      {
        name: "CSS",
        icon: <i class="devicon-css3-plain-wordmark colored"></i>
        ,
      },
      {
        name: "Bootstrap",
        icon:<i class="devicon-bootstrap-plain-wordmark colored"></i>
        ,
      },
 
    ],
  },
  {
    type: "Backend",
    list: [
      {
        name: "Node",
        icon: <i class="devicon-nodejs-plain-wordmark colored"></i>
        ,
      },
      {
        name: "express",
        icon: <i class="devicon-express-original-wordmark colored"></i>
        ,
      },

      {
        name: "Firebase",
        icon: <i class="devicon-firebase-plain-wordmark colored"></i>
        ,
      },
      {
        name: "Django",
        icon:<i class="devicon-django-plain-wordmark colored"></i>

        ,
      },
     
      
    ],
  },
  {
    type: "Database",
    list: [
      {
        name: "Mongodb",
        icon:<i class="devicon-mongodb-plain-wordmark colored"></i>
        ,
      },
      {
        name: "MySQL",
        icon: <i class="devicon-mysql-plain-wordmark colored"></i>
        ,
      },
      {
        name: "Postgresql",
        icon: <i class="devicon-postgresql-plain-wordmark colored"></i>
        ,
      },
    ]
  },
  {
    type: "Others",
    list: [
 
 
  
      {
        name: "Raspberrypi",
        icon: <i class="devicon-raspberrypi-line-wordmark colored"></i>
        ,
      },
      {
        name: "Linux",
        icon:<i class="devicon-linux-plain colored"></i>
        ,
      },
      {
        name: "Github",
        icon: <i class="devicon-github-original colored"></i>

      },
      {
        name: "VsCode",
        icon: <i class="devicon-vscode-plain-wordmark colored"></i>
        ,
      },
   
    ],
  },

]


