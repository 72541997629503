import React from 'react'

const Blogs = () => {
    return (
        <div>
            <h1 style={{textAlign:'center'}}> Blogs Coming Soon!!!!!!!!</h1>
        </div>
    )
}

export default Blogs
