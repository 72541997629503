import React from "react";
import "./contact.css";
import SocialContact from "../../common/social-contact/index";
import Separator from "../../common/separator/index";
import { useForm } from "react-hook-form";

import Swal from "sweetalert2";
import emailjs from 'emailjs-com';


function Contact() {
  const SERVICE_ID = "service_vcl12js";
  const TEMPLATE_ID = "template_rbshn15";
  const USER_ID = "user_Ebl3AKyGr3RbyD3KIfFLa";

  const { register, handleSubmit,reset } = useForm();
  const onSubmit = data => {
     
    emailjs.send(SERVICE_ID, TEMPLATE_ID,data, USER_ID)
    .then((result) => {
      console.log(result.text);
      Swal.fire({
        icon: "success",
        title: "Message Sent Successfully"
      })
      reset()
    }, (error) => {
      console.log(error.text);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
  }
  ;
  
  return (
    <div className="contact">
      <Separator />
      <label className="section-title">Contact</label>
      <div className="contact-container">
        <div className="contact-left">
        <form onSubmit={handleSubmit(onSubmit)}>
      <input type="text" placeholder="Your Name" {...register("name",)} /> <br/>
      <input type="email" placeholder="Your Email" {...register("email",)} /> <br/>
      <textarea type="text" placeholder="Your Message" rows="4" cols="50" {...register("message",)} /> <br/>
   
      <input className="submitBtn" type="submit" value="Send" />
    </form>
          <p>Want to get in touch? Contact me on any of the platform</p>
          <SocialContact />
        </div>
        <div className="download">
          <a href="https://drive.google.com/file/d/17t8NC01CkgYBesMqG3cpzHO7wJTGaizM/view" target="_blank">
            <i class="fi-rr-cloud-download download-icon" />
            Download Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
