import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/index";
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import ProjectDetail from "./components/projectDetail/ProjectDetail";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./components/body/about";
import Blogs from "./components/blogs/Blogs";
import AboutMe from "./components/aboutMe/AboutMe";

function App() {
  return (
    <div className="home" >
    <BrowserRouter>
    
    <Header />
   
     
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/projects/:id" element={<ProjectDetail />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/blogs" element={<Blogs />} />
    </Routes>
    </BrowserRouter>
      
    <Footer />
    </div>
  );
}

export default App;
