import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./mobile.css";
function Mobile({ isOpen, setIsOpen }) {
  return (
    <div className="mobile">
      <div onClick={() => setIsOpen(!isOpen)} className="close-icon">
        <i class="fi-rr-cross-circle"></i>
      </div>
      <div className="mobile-options">
      <div className="mobile-option">
          <Link to="/">
            <i class="fi-rr-home-alt option-icon"></i>Home
          </Link>
        </div>
        <div className="mobile-option">
          <HashLink to="/#projects">
            <i class="fi-rr-edit-alt option-icon"></i>Projects
          </HashLink>
        </div>
        <div className="mobile-option">
          <HashLink to="/#skills">
            <i class="fi-rr-laptop option-icon"></i>Skills
          </HashLink>
        </div>
        <div className="mobile-option">
          <HashLink to="/#work">
            {" "}
            <i class="fi-rr-briefcase option-icon"></i>Work
          </HashLink>
        </div>
        <div className="mobile-option">
          <HashLink to="/#contact">
            <i class="fi-rr-user option-icon"></i>Contact
          </HashLink>
        </div>
        <div className="mobile-option">
        <Link to="/about">
       About
        </Link>
      </div>
      <div className="mobile-option">
        <Link to="/blogs">
    
          Blogs
        </Link>
      </div>
      </div>
    </div>
  );
}

export default Mobile;
