export const ProjectData = [
  {
    id: 1,
    title: "Time Keeper - Online Shop",
    about: `● A MERN project included dashboard admin and users controls.

      ● Users can place orders, manage their orders, and review the site.

      ● Admin can add a new product, create a new admin for the page and
      manage all the products and orders.`,
    full_details: `
     
Unique Design and Interactive UI|
Featured Home page|
Banner,Best Products, Customer Reviews,About Section,Footer Section|
Firebase EmailPassword Authentication with additional Username Registration|
Exploring all products Page|
Protected route for unauthenticate users|
Dynamic Routing|
Parchase Product with automatic fill user data from login system|
Admin Dashboard and User Dashboard|
User Dashboard|
User can see his/her all orders information and status|
User can cancel his/her order|
User can write a review and rating.|
Admin Dashboard|
Admin can see all other,products informations|
Admin can add a new product.|
Admin can delete or update status of a order from Manage Orders|
Admin can delete Product|
Admin can give a role admin to a normal user.|
Confirmation message before canceling/deleting any order/products.|
Data preload spinner|
Realistic Header,Footer
      `,
    tags: [
      "React JS",
      "React Router",
      "Bootstrap",
      "React-Hook-Form",
      "Express",
      "Firebase authentication",
      "MongoDB",
      "Heroku",
    ],
    demo: "https://timekeeper-785db.web.app/",
    github: "https://github.com/icerahi/timekeeper_react_ecommerce",
    images: [
      "https://user-images.githubusercontent.com/32910469/144034636-7cc86bb8-9b27-4dcf-bf17-5c7eab5f1dc4.png",
      "https://user-images.githubusercontent.com/32910469/144039962-fcf8d885-94bf-46cc-9bf6-088c91095ff8.png",
      "https://user-images.githubusercontent.com/32910469/144029435-00c610e4-4efb-433b-989c-ac89f5b3cbaa.jpeg",
    ],
  },

  {
    id: 2,
    title: "Fame Photo",
    about: `● Fullstack photos and albums sharing platform where people can create,
       manage their own profile and share their photos and albums

       ● Unauthenticated users able to view publicly open photos and albums

       ● Users can delete, edit or make private their own photos and albums.`,
       full_details:`
     
Login/Register|
Open feed page with all public pictures|
Album page with all open albums|
User page with all registered users|
Profile Page with all photos and albums|
single photo view|
single album view|
authorize users can update,delete their albums|
user can upload photo with captions|
user can select album|
user can create a new album|
user can hide a album|
user can update their profile information|
full responsive flexiable user interface,etc|
`,
    tags: [
      "Reactjs",
      "React Router",
      "ContextAPI",
      "Bootstrap",
      "Python",
      "Django",
      "DRF",
    ],
    demo: "https://fame-photo.web.app/",
    github: "https://github.com/icerahi/FamePhoto",
    images: [
      "https://user-images.githubusercontent.com/32910469/144040196-ec544d3f-a1d7-405e-9593-eb3271d637a1.png",
      "https://user-images.githubusercontent.com/32910469/144040456-2c447bfe-5b82-449f-b2c4-35c1bfcdaab5.png",
      "https://user-images.githubusercontent.com/32910469/144028938-6dfa888c-d6ec-4a86-9b0c-4ba611dbe409.jpeg",
    ],
  },

  {
    id: 3,
    title: " Tour Hobe",
    about: `● a MERN Stack TravelAgency’s website who provides tour packages
       ● Admin can manage all orders and add new tour packages.
       ● Users can book packages and manage their own booking.`,
       full_details:`
Unique Design|
Home Page|
Banner,Hot Deals(trips list),Specialities,Newsletter,partners sections|
Continue with Google|
Trip Booking page with details and autofilled Name & Email|
Protected route for unauthenticate users.|
Dynamic Routing|
Login/Logout Indicator on Navbar with profile Image(if have) and Username|
|My Bookings,Manage All Bookings,Add a new Trip Option (Only Authenticated User can access)|
User can cancel his/her own booking from my booking option|
Admin(currently logged user) can make approve & delete any booking from manage bookings option|
Confirmation message before canceling/deleting any booking.|
Data preload spinner|
Realistic Header,Footer|
       `,
    tags: [
      "Reactjs",
      "React Router",
      "Firebase",
      "ContextAPI",
      "Bootstrap",
      "React hook form",
      "Nodejs",
      "Expressjs",
      "MongoDB",
    ],
    demo: "https://trip-hobe.web.app/",
    github: "https://github.com/icerahi/tourhobe_react",
    images: [
      "https://user-images.githubusercontent.com/32910469/144040628-e5429b60-9d57-4f8e-9803-f5c4f613b394.png",
      "https://user-images.githubusercontent.com/32910469/144040851-ca05d3f9-9d05-4325-916e-28ede4b11c53.png",
      "https://user-images.githubusercontent.com/32910469/144029624-181b0420-43d3-4649-bc45-60fd8b10eceb.jpeg",
    ],
  },
  {
    id: 4,
    title: "Refrain Addiction",
    about: `Refrain Addition is a pioneer drug addiction treatment center where you get freedom from addiction and we are dedicated to help people who are suffering from drug addiction.It's a network of providers that is committed to helping individuals who are struggling to break the cycle of substance abuse.`,
    full_details:`
Unique Design|
Home Page|
Banner,Best Services,Specialities,Collaboration sections|
Booking Appointment Page|
All Services Page|
Service Details Page|
404 NotFound Page|
Login/Register via Emai Password or Google|
Protected route for unauthenticate users.|
Dynamic Routing|
Login/Logout Indicator on Navbar with profile Image(if have) and Username|`,
    tags: [
      "Reactjs",
      "React-Router-DOM",
      "Firebase",
      "ContextAPI",
      "Bootstrap",
      "React-Hook-Form",
    ],
    demo: "https://refrain-addiction.web.app/",
    github: "https://github.com/icerahi/refrain-addiction-react",
    images: [
      "https://user-images.githubusercontent.com/32910469/144041006-221d3c1a-7a43-4c73-8044-e4978f627a60.png",
      "https://user-images.githubusercontent.com/32910469/144041098-1ce94bda-0059-4de0-b853-c6e8f3c4be7f.png",
      "https://user-images.githubusercontent.com/32910469/144029858-d8d0e355-a2dc-4b5f-be51-109f4e600171.jpeg",
    ],
  },
];
