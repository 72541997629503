import React from "react";
import { Link,useNavigate } from "react-router-dom";
import "./project-card.css";
function ProjectCard({ project }) {
  const navigate = useNavigate()
  return (
    <div className="project-card">
      <div className="project-info">
        <label className="project-title"> <Link to={`projects/${project.id}`}>{project.title}</Link> </label>
        <div className="project-links">
          {project.demo && (
            <a className="project-link" href={project.demo}>
              <div className="link-button">
                <i class="fi-rr-globe"></i>
                Live
              </div>
            </a>
          )}
          {project.github && (
            <a className="project-link" href={project.github}>
              <div className="link-button">
                <i class="devicon-github-original colored"></i> Github
              </div>
            </a>
          )}
        </div>
        <p>{project.about}.... <Link to={`/projects/${project.id}`}>Learn More</Link> </p>
        <div className="project-tags">
          {project.tags.map((tag) => {
            return <label className="tag">{tag}</label>;
          })}
        </div>
      </div>
      
      <img onClick={()=> navigate(`/projects/${project.id}`)} src={project?.images[0]} className="project-photo" />
    </div>
  );
}
 
export default ProjectCard;
