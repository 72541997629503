import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./web.css";
function Web() {
  return (
    <div className="web">
      <div className="web-option">
        <HashLink to="/#projects">
      Projects
        </HashLink>
      </div>
      <div className="web-option">
        <HashLink to="/#skills">
          Skills
        </HashLink>
      </div>
      <div className="web-option">
        <HashLink to="/#work">
         Work
        </HashLink>
      </div>
      <div className="web-option">
        <HashLink to="/#contact">
       Contact
        </HashLink>
      </div>
      <div className="web-option">
        <Link to="/about">
       About
        </Link>
      </div>
      <div className="web-option">
        <Link to="/blogs">
    
          Blogs
        </Link>
      </div>
    </div>
  );
}

export default Web;
