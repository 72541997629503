import React from "react";
import './AboutMe.css'
const AboutMe = () => {
  return (
    <div className="about" style={{backgroundColor:'whitesmoke'}}>
      <div className="about-top">
      <div className="about-photo" >
          <img
            src={require("../../assets/image/intro.png").default}
            className="picture" style={{textAlign:'center',padding:'5px 10px'}}
          />
   
        </div>
      
        <div className="about-info">
  I'm <span className="info-name" style={{textAlign:'center'}}> Imran Hasan.</span>
          A Curious, Passionate Jr. Full Stack Developer. Love to take Dare and
          handle it professionally. Handle difficulties and errors efficient
          way. And I always try to keep myself with new technologies learning
          cycle. I'm a self-driven programmer who loves taking challenges and
          going onward to solve complex problems in day-to-day life.
          <br />
          <button
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/17t8NC01CkgYBesMqG3cpzHO7wJTGaizM/view",
                "_blank"
              )
            }
            className="resumeBtn" style={{margin:'5px auto'}}
          >
            My Resume
          </button>
        </div>
        
      </div>
      
    </div>
  );
};

export default AboutMe;
