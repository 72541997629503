import React from "react";
import { useParams } from "react-router-dom";
import { ProjectData } from "../../data/projects";
import './projectDetail.css'

const ProjectDetail = () => {
  const { id } = useParams();
  const project = ProjectData.find((project) => project.id == id);
  const features = project?.full_details.split('|')
  return (
    <div className="project-card">
      <div className="project-info">
        <label className="project-title"> {project.title} </label>
        <div className="project-links">
          {project.demo && (
            <a className="project-link" href={project.demo}>
              <div className="link-button">
                <i class="fi-rr-globe"></i>
                Live
              </div>
            </a>
          )}
          {project.github && (
            <a className="project-link" href={project.github}>
              <div className="link-button">
                <i class="devicon-github-original colored"></i> Github
              </div>
            </a>
          )}
        </div>
        <p>{project.about}</p>
        <strong>Features:</strong>
        <div className="features">
          
            {features.map((feature,index)=>(
              <li key={index}>{feature}</li>
            ))}
             
        </div>
        <strong>Used Technologies:</strong>
        <div className="project-tags">
          {project.tags.map((tag) => {
            return <label className="tag">{tag}</label>;
          })}
        </div>
        <h3>Screenshots:</h3>
        <div className="project-detail-photos"
 
        >
          {project?.images.map((image, index) => (
            <img
              key={index}
              src={`${image}`}
              style={{ margin: "10px" }}
            
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
