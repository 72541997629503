import React from "react";
import SocialContact from "../../common/social-contact";
import "./about.css";
function About() {
  return (
    <div className="about">
      <div className="about-top">
        <div className="about-info">
          Hello There 👋, I am <br />
          <span className="info-name">Imran Hasan</span>.<br /> I'm a passionate
        Junior  Fullstack Developer
<br/>
        <button onClick={()=> window.open("https://drive.google.com/file/d/17t8NC01CkgYBesMqG3cpzHO7wJTGaizM/view", "_blank")} className="resumeBtn">
          My Resume
        </button>
        </div>
        <div className="about-photo">
          <img
            src={require("../../../assets/image/intro.png").default}
            className="picture"
          />
        </div>
      </div>
      <SocialContact />
    </div>
  );
}

export default About;
